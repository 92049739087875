import { ArrowDropDown, Star, StarOutline } from '@mui/icons-material';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import useExperiments from 'api/experiments';
import { addFavoriteEnvironment, removeFavoriteEnvironment } from 'api/utils';
import React from 'react';

import EnvironmentSelectorSkeleton from './EnvironmentSelector.skeleton';
import * as Styled from './EnvironmentSelector.styles';
import useEnvironmentSelector from './useEnvironmentSelector';

type EnvironmentSelectorProps = {
  useNativeDropdown?: boolean;
};

export default function EnvironmentSelector(props: EnvironmentSelectorProps) {
  const { useNativeDropdown = false } = props;
  const {
    open,
    ready,
    reports,
    refreshReports,
    onClose,
    onClick,
    anchorEl,
    infoIcon,
    onReportClick,
    reportName,
    organization,
  } = useEnvironmentSelector();

  const { isExperimentEnabled } = useExperiments();

  if (!ready) return <EnvironmentSelectorSkeleton />;

  const reportLists = reports?.map((report) => (
    <MenuItem
      value={report.name}
      key={report.id}
      onClick={() => onReportClick(report)}
      sx={
        isExperimentEnabled('feature.environmentselector.favorites') || isExperimentEnabled('user.isInternal')
          ? {
              p: '2px 8px 2px 8px !important',
            }
          : {}
      }
    >
      {(isExperimentEnabled('feature.environmentselector.favorites') || isExperimentEnabled('user.isInternal')) && (
        <ListItemIcon>
          <IconButton
            size="small"
            onClick={(e) => {
              if (report.isFavorite) removeFavoriteEnvironment(report.name);
              else addFavoriteEnvironment(report.name);
              e.stopPropagation();
              refreshReports();
            }}
          >
            {report.isFavorite ? <Star color="warning" /> : <StarOutline />}
          </IconButton>
        </ListItemIcon>
      )}
      {report.name}
    </MenuItem>
  ));

  if (useNativeDropdown)
    return (
      <TextField
        fullWidth
        select
        label="Environment Selector"
        value={reportName}
        sx={{
          // eslint-disable-next-line @typescript-eslint/naming-convention
          '.MuiListItemIcon-root': {
            display: 'none',
          },
        }}
      >
        {reportLists}
      </TextField>
    );

  return (
    <>
      <Styled.ListItemButton onClick={onClick} data-testid="global-environmentselector-button">
        {infoIcon && <Tooltip title={infoIcon.tooltip}>{infoIcon.icon}</Tooltip>}
        <ListItemText>
          <Typography variant="body2" noWrap>
            {reportName}
          </Typography>
          <Typography variant="body2" color="GrayText">
            {organization}
          </Typography>
        </ListItemText>
        <ArrowDropDown />
      </Styled.ListItemButton>
      <Menu
        anchorEl={anchorEl}
        onClose={onClose}
        open={open}
        MenuListProps={{
          //@ts-expect-error data- attrib
          //eslint-disable-next-line
          'data-testid': 'global-environmentselector-list',
        }}
      >
        {/*{reports.map((report) => [
          <ListSubheader sx={{ lineHeight: 1.5 }} key={report.name + '1'}>
            {report.name}
          </ListSubheader>,
          <List disablePadding key={report.name + '2'}>
            {report.variants.map((env) => (
              <MenuItem key={env.id} value={env.id} onClick={() => onReportClick(env)} sx={{ pl: 4 }}>
                {env.name}
              </MenuItem>
            ))}
          </List>,
        ])}*/}
        {reportLists}
      </Menu>
    </>
  );
}
