import { ClickHouseApiResponseSchemaBuilder } from 'api/pages/schemas/clickhouse';
import { z } from 'zod';

// +----------+
// | Response |
// +----------+

//  left nav section
export const leftNavId = 'lftN4V';

export const DashboardInsightsLeftNavHeaders = [
  'query_variables',
  'title',
  'insight',
  'risk_level',
  'category_friendly_name',
  'report_summary_name',
  'last_seen',
  'description',
  'business_impact',
  'recommendation',
  'table_message',
] as const;
export type DashboardInsightsLeftNavHeaderKeys = (typeof DashboardInsightsLeftNavHeaders)[number];

// disable strict mode because "table_message" is optional
export const DashboardInsightsLeftNavModelSchema = ClickHouseApiResponseSchemaBuilder(
  DashboardInsightsLeftNavHeaders,
  false
);

export type DashboardInsightsLeftNavModel = z.infer<typeof DashboardInsightsLeftNavModelSchema>;

// Final payload

export const DashboardInsightsModelSchema = z.object({
  [leftNavId]: DashboardInsightsLeftNavModelSchema.optional(),
});

export type DashboardInsightsModel = z.infer<typeof DashboardInsightsModelSchema>;
