import { useAxios } from 'api';
import {
  ApiDataExportDimensionsAndMetricsSchema,
  DataExportDimensionsAndMetrics,
  EnvironmentId,
  ReportId,
} from 'api/apiTypes';
import routes from 'api/routes';
import { useRegisterMiddlewareHook } from 'api/utils';
import useSWR, { SWRResponse } from 'swr';

export function useDataExportDimensionsAndMetrics(reportId: ReportId): SWRResponse<DataExportDimensionsAndMetrics> {
  const { get } = useAxios();
  const report = useRegisterMiddlewareHook('useDataExportDimensionsAndMetrics', reportId);

  return useSWR(
    report
      ? routes.dataExport.getDimensionsAndMetrics(report?.summary.json.environmentId ?? ('' as EnvironmentId))
      : null,
    async (url) => {
      const response = await get(url, { withCredentials: true });
      return ApiDataExportDimensionsAndMetricsSchema.parse(response.data);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
    }
  );
}
