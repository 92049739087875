import { useCurrentUser, useReport, useReportId } from 'api';
import {
  ApiDataExport,
  ApiDataExportMetric,
  ApiDataExportPayloadDimension,
  ApiDataExportPostfix,
  ApiDataExportSchema,
} from 'api/apiTypes';
import { withoutError } from 'api/utils';
import { Moment } from 'moment';
import React, { createContext, PropsWithChildren, useMemo, useState } from 'react';
import { ControllerState } from 'utils';

import { UiPostfix } from './components/Segmentation/segmentation.controller';
import { useParams } from './dataExport.params';

type NewDataExportProvider = {
  payload: ApiDataExport | null;
  metrics: ControllerState<ApiDataExportMetric[]>;
  dimensions: ControllerState<ApiDataExportPayloadDimension[]>;
  postfix: ControllerState<ApiDataExportPostfix[] | null>;
  date: {
    start: ControllerState<Moment | null>;
    end: ControllerState<Moment | null>;
  };
  format: ControllerState<'csv' | 'json'>;
  emails: ControllerState<string[]>;
  limit: ControllerState<number | null>;
  operands: ControllerState<UiPostfix[]>;
};

export const NewDataExportContext = createContext({} as NewDataExportProvider);

export function NewDataExportProvider(props: PropsWithChildren) {
  const { name } = useParams();
  const reportId = useReportId();
  const report = withoutError(useReport(reportId));
  const user = withoutError(useCurrentUser());

  const [selectedMetrics, setSelectedMetrics] = useState<ApiDataExportMetric[]>([]);
  const [selectedDimensions, setSelectedDimensions] = useState<ApiDataExportPayloadDimension[]>([]);
  const [postfix, setPostfix] = useState<ApiDataExportPostfix[] | null>(null);
  const [startDate, setStartDate] = useState<Moment | null>(null);
  const [endDate, setEndDate] = useState<Moment | null>(null);
  const [format, setFormat] = useState<'csv' | 'json'>('csv');
  const [emails, setEmails] = useState<string[]>([]);
  const [limit, setLimit] = useState<number | null>(null);
  const [operands, setOperands] = useState<UiPostfix[]>([]);

  const payload = useMemo<ApiDataExport | null>(() => {
    if (!name || !postfix || !startDate || !report) return null;
    try {
      return ApiDataExportSchema.parse({
        name,
        emails: [user?.email, ...emails],
        format,
        filters: postfix,
        metrics: true ? [] : selectedMetrics,
        dimensions: selectedDimensions,
        environmentGuid: report.summary.json.environmentId,
        ...(limit !== null
          ? {
              limit,
            }
          : {}),
        dateRange: {
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate ? endDate.format('YYYY-MM-DD') : startDate.format('YYYY-MM-DD'),
        },
      });
    } catch (e) {
      window.ApiLogs.push({
        date: new Date(),
        value: {
          path: ['data-export'],
          error: e,
        },
      });
      return null;
    }
  }, [
    emails,
    endDate,
    format,
    limit,
    name,
    postfix,
    report,
    selectedDimensions,
    selectedMetrics,
    startDate,
    user?.email,
  ]);

  const value: NewDataExportProvider = useMemo(
    () => ({
      payload,
      limit: { current: limit, onChange: setLimit },
      format: { current: format, onChange: setFormat },
      emails: { current: emails, onChange: setEmails },
      postfix: { current: postfix, onChange: setPostfix },
      operands: { current: operands, onChange: setOperands },
      metrics: { current: selectedMetrics, onChange: setSelectedMetrics },
      dimensions: { current: selectedDimensions, onChange: setSelectedDimensions },
      date: {
        start: { current: startDate, onChange: setStartDate },
        end: { current: endDate, onChange: setEndDate },
      },
    }),
    [emails, endDate, format, limit, operands, payload, postfix, selectedDimensions, selectedMetrics, startDate]
  );

  window.ApiObjects.DataExportContext = value;

  return <NewDataExportContext.Provider value={value} {...props} />;
}
